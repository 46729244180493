import axios, { AxiosError } from 'axios'

interface IPayload {
  url: string
  code: string
}

const downloadQr = async (payload: IPayload) => {
  const { url, code } = payload

  await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute(
      'download',
      'table' + code + '.png'
    )
    document.body.appendChild(fileLink)
    fileLink.click()
  }).catch((error: AxiosError) => {
    console.log('====================================');
    console.log('downloadQr--ERROR', error);
    console.log('====================================');
  })
}

export default downloadQr
