
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import vPargnation from '@/components/v-pagination.vue'
import vSectionQrDetail from '@/components/profile/v-section-qr-detail.vue'
import vIconDownload from '@/assets/images/icons/v-icon-download.vue'
import downloadQr from '@/utils/downloadQr'
import { ITable } from '@/types/restaurant'
export default defineComponent ({
  name: 'VSectionRr',
  components: {
    vPargnation,
    vSectionQrDetail,
    vIconDownload
  },
  data() {
    return {
      detail: false,
      tableData: {},
      tableId: '',
      range_from: '',
      range_to: '',
      isLoading: false,
      reset: false
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_TABLES', 'GET_SELECTED_TABLE']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  async mounted() {
    if (this.range_from || this.range_to) this.reset = true

    await this.FETCH_TABLES({
      token: this.GET_AUTHENTIFICATED,
      page: 1,
    })
    this.TOGGLE_PRELOADER(false)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('restaurant', [
      'FETCH_TABLES',
      'FETCH_SELECTED_TABLE',
    ]),
    togglePreloader(status: boolean): void {
      this.TOGGLE_PRELOADER(status)
    },
    async resetSerch(): Promise<void> {
      this.isLoading = true
      await this.FETCH_TABLES({
        token: this.GET_AUTHENTIFICATED,
        page: 1,
      })
      this.range_from = ''
      this.range_to = ''
      this.reset = false
      this.isLoading = false
    },
    async search(): Promise<void> {
      if (this.range_from.length || this.range_to.length) {
        this.isLoading = true
        await this.FETCH_TABLES({
          token: this.GET_AUTHENTIFICATED,
          page: 1,
          range_from: this.range_from.length ? parseInt(this.range_from) : null,
          range_to: this.range_to.length ? parseInt(this.range_to) : null,
        })
        this.reset = true
        this.isLoading = false
      }
    },
    async onClickPagination(num: string): Promise<void> {
      this.TOGGLE_PRELOADER(true)
      await this.FETCH_TABLES({
        page: num,
        range_from: this.range_from.length ? parseInt(this.range_from) : null,
        range_to: this.range_to.length ? parseInt(this.range_to) : null,
        token: this.GET_AUTHENTIFICATED,
      })
      this.TOGGLE_PRELOADER(false)
    },

    onDetail(): void {
      this.detail = !this.detail
    },

    toggleId(table: ITable): void {
      this.tableId = table.id
    },

    onDetailTable(table: ITable): void {
      this.fetchQr(table).then(() => this.onDetail())
    },

    async fetchQr(table: ITable): Promise<void> {
      this.togglePreloader(true)
      this.toggleId(table)

      const table_id = this.tableId

      try {
        let shop_id = ''

        if (table.shopId.toString().length) {
          shop_id = '?shop_id=' + table.shopId.toString()
        }

        const data = {
          table_id: table_id,
          shop_id: shop_id,
          token: this.GET_AUTHENTIFICATED
        }

        await this.FETCH_SELECTED_TABLE(data)

        this.togglePreloader(false)
      } catch (e) {
        console.error('error', e)
        this.togglePreloader(false)
      }
    },

    async downloadQr(type?: string): Promise<void> {
      const data = {
        url: type === 'qr' ? this.GET_SELECTED_TABLE?.qr : this.GET_SELECTED_TABLE?.qr_invert,
        code: this.GET_SELECTED_TABLE?.code
      }
      await downloadQr(data)
    },

    downloadBlackQr(table: ITable) {
      this.fetchQr(table).then(() => this.downloadQr())
    },
  },
})
